import { MutableRefObject, useEffect, useRef } from 'react';
import { InformedDesignExtension } from '../InformedDesignExtension';
import { ProductReleaseData } from '../utils/types';
// need to load theme right here to make it available in dock panels
import '@mid-react-common/common/styles/midTheme';

type UseLmvInitProps = {
  token: string | undefined;
  lmvContainerRef: MutableRefObject<HTMLElement | null>;
  productReleaseData: ProductReleaseData;
  svfUrl: string;
};

export const useLmvInit = ({ token, lmvContainerRef, productReleaseData, svfUrl }: UseLmvInitProps): void => {
  const lmvInitializerStarted = useRef(false);
  const lmvRef = useRef<Autodesk.Viewing.GuiViewer3D>();

  useEffect(() => {
    if (!token || !lmvContainerRef.current) {
      return;
    }

    const theme = 'light-theme';

    // prevent from double initialization in the development mode
    lmvInitializerStarted.current = true;

    // eslint-disable-next-line new-cap
    Autodesk.Viewing.Initializer(
      {
        env: 'Local',
        getAccessToken: async (onTokenReady) => {
          onTokenReady(token, 3600);
        },
      },
      () => {
        const viewer = new Autodesk.Viewing.GuiViewer3D(lmvContainerRef.current!, {
          extensions: ['Autodesk.InformedDesign'],
        });

        // set theme to the viewer
        viewer.setTheme(theme);

        const startedCode = viewer.start();

        if (startedCode > 0) {
          console.error('Failed to create a Viewer: WebGL not supported.');
          return;
        }

        viewer.fitToView();
        lmvRef.current = viewer;

        viewer.getExtensionAsync('Autodesk.InformedDesign').then((extension: InformedDesignExtension) => {
          // set theme to the extension
          extension.setTheme(theme);
          extension.setProductReleaseDataToFetch(productReleaseData);
          extension.fetchProductRelease();
          extension.loadProductReleaseSVF(svfUrl);
        });

        console.log('LMV initialization complete.');
      },
    );

    return () => {
      lmvRef.current?.finish();
      lmvRef.current = undefined;
      Autodesk.Viewing.shutdown();
    };
  }, [token, lmvContainerRef, productReleaseData, svfUrl]);
};
