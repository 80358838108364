import { FC } from 'react';
import { useMainStore } from '../../stores/MainStore';
import { useShallow } from 'zustand/react/shallow';
import {
  ReleaseDetailsCell,
  ReleaseDetailsLabel,
  ReleaseDetailsRow,
  ReleaseDetailsTable,
  ReleaseDetailsValue,
} from './ReleaseDetails.styles';
import {
  DATETIME_FORMAT,
  EMPTY_STATE_ILLUSTRATION_TYPES,
  ExpandableTextSection,
  MIDEmptyState,
  ReleaseStatusWithColorBar,
  SpinnerOverlayCentered,
  commonText,
} from '@mid-react-common/common';
import { format } from 'date-fns';
import text from '../../text.json';
import { isDCRfaOutput } from 'mid-utils';

export const ReleaseDetailsPanel: FC = () => {
  const { productRelease, loading, hasError } = useMainStore(
    useShallow((state) => ({
      productRelease: state.productRelease,
      loading: state.productReleaseLoading,
      hasError: state.productReleaseLoadingError,
    })),
  );

  const rfaOutput = productRelease?.outputs.find(isDCRfaOutput);

  if (hasError) {
    return (
      <MIDEmptyState
        title={text.productReleaseLoadingErrorTitle}
        description={text.productReleaseLoadingErrorDescription}
        illustrationType={EMPTY_STATE_ILLUSTRATION_TYPES.SYSTEM_ERROR}
      />
    );
  }

  if (!productRelease || loading) {
    return <SpinnerOverlayCentered />;
  }

  return (
    <ReleaseDetailsTable>
      <ReleaseDetailsRow>
        <ReleaseDetailsLabel>{text.publishedAs}</ReleaseDetailsLabel>
        <ReleaseDetailsValue>{productRelease.isConfigurable ? text.configurable : text.static}</ReleaseDetailsValue>
      </ReleaseDetailsRow>
      <ReleaseDetailsRow>
        <ReleaseDetailsLabel>{text.category}</ReleaseDetailsLabel>
        <ReleaseDetailsValue>{rfaOutput?.options.category}</ReleaseDetailsValue>
      </ReleaseDetailsRow>
      <ReleaseDetailsRow>
        <ReleaseDetailsLabel>{text.family}</ReleaseDetailsLabel>
        <ReleaseDetailsValue>{rfaOutput?.options.family}</ReleaseDetailsValue>
      </ReleaseDetailsRow>
      <ReleaseDetailsRow>
        <ReleaseDetailsLabel>{text.representation}</ReleaseDetailsLabel>
        <ReleaseDetailsValue>{rfaOutput?.options.modelStates.join('; ')}</ReleaseDetailsValue>
      </ReleaseDetailsRow>
      <ReleaseDetailsRow>
        <ReleaseDetailsLabel>{text.releaseNumber}</ReleaseDetailsLabel>
        <ReleaseDetailsValue>{productRelease.release}</ReleaseDetailsValue>
      </ReleaseDetailsRow>
      <ReleaseDetailsRow>
        <ReleaseDetailsLabel>{text.status}</ReleaseDetailsLabel>
        <ReleaseDetailsCell>
          <ReleaseStatusWithColorBar status={productRelease.status} />
        </ReleaseDetailsCell>
      </ReleaseDetailsRow>
      <ReleaseDetailsRow>
        <ReleaseDetailsLabel>{text.notes}</ReleaseDetailsLabel>
        <ReleaseDetailsCell>
          <ExpandableTextSection content={productRelease.notes ?? commonText.releaseNotes.notesUnspecified} />
        </ReleaseDetailsCell>
      </ReleaseDetailsRow>
      <ReleaseDetailsRow>
        <ReleaseDetailsLabel>{text.created}</ReleaseDetailsLabel>
        <ReleaseDetailsValue>{format(Date.parse(productRelease.createdAt), DATETIME_FORMAT)}</ReleaseDetailsValue>
      </ReleaseDetailsRow>
    </ReleaseDetailsTable>
  );
};
