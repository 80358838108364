// this store is used to store some shared state between components

import { ProductRelease } from '@adsk/offsite-dc-sdk';
import { create } from 'zustand';

type MainStore = {
  productRelease?: ProductRelease;
  productReleaseLoading: boolean;
  productReleaseLoadingError: boolean;
  setProductRelease: (productRelease: ProductRelease) => void;
  setProductReleaseLoading: (productReleaseLoading: boolean) => void;
  setProductReleaseLoadingError: (productReleaseLoadingError: boolean) => void;
};

export const useMainStore = create<MainStore>((set) => ({
  productRelease: undefined,
  productReleaseLoading: false,
  productReleaseLoadingError: false,
  setProductReleaseLoading: (productReleaseLoading: boolean) =>
    set((state) => ({ ...state, productReleaseLoading, productReleaseLoadingError: false })),
  setProductReleaseLoadingError: (productReleaseLoadingError: boolean) =>
    set((state) => ({ ...state, productReleaseLoadingError })),
  setProductRelease: (productRelease: ProductRelease) => set((state) => ({ ...state, productRelease })),
}));
