import { createTheme } from '@mui/material/styles';
import type {} from '@mui/x-data-grid/themeAugmentation';
// @mui/x-data-grid/themeAugmentation must come before @mui/lab/themeAugmentation
import type {} from '@mui/lab/themeAugmentation';
import { themeColors, themeScreenSizes, themeTypography, themeVar } from './midTheme.constants';

/**
 * We have to specify the module augmentation right in this file (not in a separate d.ts file)
 * otherwise other monorepo apps won't see them
 * https://mui.com/material-ui/customization/theming/#typescript
 */
declare module '@mui/material/styles' {
  interface Theme {
    var: typeof themeVar;
    colors: typeof themeColors;
    screenSizes: typeof themeScreenSizes;
  }
  // allow configuration using `createTheme`
  interface ThemeOptions {
    var: typeof themeVar;
    colors: typeof themeColors;
    screenSizes: typeof themeScreenSizes;
  }

  interface TypographyVariants {
    label2: React.CSSProperties;
  }

  interface TypographyVariantsOptions {
    label2?: React.CSSProperties;
  }
}

// Update the Typography's variant prop options
declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    label2: true;
  }
}

export const lightTheme = createTheme({
  // CUSTOM PROPS
  var: themeVar,
  colors: themeColors,
  screenSizes: themeScreenSizes,

  // TEXT
  typography: {
    ...themeTypography,
    caption: {
      color: themeColors.primary.charcoal[700],
    },
  },

  // COLORS
  palette: {
    background: {
      default: themeColors.surface.lightGray[10],
    },
    text: {
      primary: themeColors.primary.black,
      secondary: themeColors.primary.charcoal[700],
    },
    primary: {
      main: themeColors.primary.autodeskBlue[500],
    },
    secondary: {
      main: themeColors.brand.darkSlate,
    },
    success: {
      main: themeColors.statusColors.lightGray.success,
    },
    warning: {
      main: themeColors.statusColors.lightGray.warning,
    },
    error: {
      main: themeColors.statusColors.lightGray.error,
    },
    info: {
      main: themeColors.primary.autodeskBlue[500],
    },
    divider: themeColors.surface.lightGray[25],
  },

  // OVERRIDES
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        '.mid-bg-zebra:nth-of-type(even)': {
          backgroundColor: themeColors.surface.lightGray[20],
        },
        '.mid-bg-shadow': {
          backgroundColor: `${themeColors.surface.lightGray[20]} !important`,
        },
        '.mid-border-shadow': {
          border: `${themeVar.borderHeight}px solid ${themeColors.surface.lightGray[25]}`,
        },
        '.mid-status-bar-active': {
          borderLeft: `solid ${themeVar.releaseStatusColorBarWidth}px ${themeColors.statusColors.lightGray.success}`,
        },
        '.mid-status-bar-default': {
          borderLeft: `solid ${themeVar.releaseStatusColorBarWidth}px ${themeColors.primary.autodeskBlue[500]}`,
        },
        '.mid-status-bar-deprecated': {
          borderLeft: `solid ${themeVar.releaseStatusColorBarWidth}px ${themeColors.statusColors.lightGray.warning}`,
        },
        '.mid-status-bar-inactive': {
          borderLeft: `solid ${themeVar.releaseStatusColorBarWidth}px ${themeColors.statusColors.lightGray.warning}`,
        },
        '.mid-status-bar-obsolete': {
          borderLeft: `solid ${themeVar.releaseStatusColorBarWidth}px ${themeColors.statusColors.lightGray.error}`,
        },
        '.mid-status-primary': {
          fontWeight: 'bold !important',
        },
        '.mid-status-secondary': {
          color: `${themeColors.primary.charcoal[700]}`,
        },
        '.mid-disabled-row': {
          color: `${themeColors.primary.charcoal[300]}`,
        },
        '.mid-release-notes-border-normal': {
          border: `1px solid ${themeColors.surface.lightGray[35]}`,
        },
        '.mid-release-notes-border-error': {
          border: `1px solid ${themeColors.statusColors.lightGray.error}`,
        },
        '.mid-release-notes-footer-text-error': {
          color: themeColors.statusColors.lightGray.error,
        },
        '.mid-tooltip-link': {
          color: `${themeColors.primary.autodeskBlue[300]} !important`,
        },
        fieldset: {
          border: 'none',
        },
        footer: {
          backgroundColor: themeColors.surface.lightGray[10],
          borderTop: `${themeVar.borderHeight}px solid ${themeColors.surface.lightGray[25]}`,
        },
      },
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          backgroundColor: themeColors.surface.lightGray[10],
          color: themeColors.primary.black,
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        root: {
          backgroundColor: themeColors.surface.lightGray[10],
        },
      },
    },
    MuiDataGrid: {
      styleOverrides: {
        root: {
          borderRadius: 0,
          borderColor: themeColors.surface.lightGray[25],
        },
        columnHeaders: {
          borderRadius: 0,
          backgroundColor: themeColors.surface.lightGray[25],
          borderColor: themeColors.surface.lightGray[25],
        },
        cell: {
          borderColor: themeColors.surface.lightGray[25],
        },
        footerContainer: {
          borderRadius: 0,
          borderColor: themeColors.surface.lightGray[25],
        },
      },
    },
    MuiTableHead: {
      styleOverrides: {
        root: {
          backgroundColor: themeColors.surface.lightGray[25],
        },
      },
    },
    MuiTableBody: {
      styleOverrides: {
        root: {
          backgroundColor: themeColors.surface.lightGray[20],
        },
      },
    },
    MuiListItem: {
      styleOverrides: {
        root: {
          ':hover': {
            backgroundColor: themeColors.surface.lightGray[25],
          },
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        root: {
          backgroundColor: themeColors.surface.lightGray[20],
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          backgroundColor: themeColors.primary.white,
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        outlined: {
          backgroundColor: themeColors.primary.white,
        },
      },
    },
    MuiRadio: {
      styleOverrides: {
        root: {
          '& svg': {
            width: '.9em',
            height: '.9em',
          },
        },
      },
    },
  },
});

export const darkTheme = createTheme({
  // CUSTOM PROPS
  var: themeVar,
  colors: themeColors,
  screenSizes: themeScreenSizes,

  // TEXT
  typography: themeTypography,

  // COLORS
  palette: {
    mode: 'dark',
    background: {
      default: themeColors.surface.darkBlue[20],
    },
    primary: {
      main: themeColors.primary.autodeskBlue[300],
    },
    success: {
      main: themeColors.statusColors.dark.success,
    },
    warning: {
      main: themeColors.statusColors.dark.warning,
    },
    error: {
      main: themeColors.statusColors.dark.error,
    },
    info: {
      main: themeColors.primary.autodeskBlue[500],
    },
    divider: themeColors.surface.darkBlue[30],
  },

  // OVERRIDES
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        '.mid-bg-zebra:nth-of-type(even)': {
          backgroundColor: themeColors.surface.darkBlue[25],
        },
        '.mid-bg-shadow': {
          backgroundColor: `${themeColors.surface.darkBlue[25]} !important`,
        },
        '.mid-border-shadow': {
          border: `${themeVar.borderHeight}px solid ${themeColors.surface.darkBlue[25]}`,
        },
        '.mid-release-notes-border-normal': {
          border: `1px solid ${themeColors.surface.lightGray[35]}`,
        },
        '.mid-release-notes-border-error': {
          border: `1px solid ${themeColors.statusColors.dark.error}`,
        },
        '.mid-release-notes-footer-text-error': {
          color: themeColors.statusColors.dark.error,
        },
        '.mid-tooltip-link': {
          color: `${themeColors.primary.autodeskBlue[300]} !important`,
        },
        fieldset: {
          border: 'none',
        },
        footer: {
          backgroundColor: themeColors.surface.darkBlue[20],
          borderTop: `${themeVar.borderHeight}px solid ${themeColors.surface.darkBlue[25]}`,
        },
      },
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          backgroundColor: themeColors.surface.darkBlue[20],
          backgroundImage: 'none',
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        root: {
          backgroundColor: themeColors.surface.darkBlue[20],
        },
      },
    },
    MuiDataGrid: {
      styleOverrides: {
        root: {
          borderRadius: 0,
          borderColor: themeColors.surface.darkBlue[25],
          backgroundColor: themeColors.surface.darkBlue[10],
        },
        columnHeaders: {
          borderRadius: 0,
          backgroundColor: themeColors.surface.darkBlue[25],
          borderColor: themeColors.surface.darkBlue[25],
        },
        cell: {
          borderColor: themeColors.surface.darkBlue[25],
        },
        footerContainer: {
          borderRadius: 0,
          borderColor: themeColors.surface.darkBlue[25],
        },
      },
    },
    MuiTableHead: {
      styleOverrides: {
        root: {
          backgroundColor: themeColors.surface.darkBlue[25],
        },
      },
    },
    MuiTableBody: {
      styleOverrides: {
        root: {
          backgroundColor: themeColors.surface.darkBlue[10],
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          borderColor: themeColors.surface.darkBlue[20],
        },
      },
    },
    MuiListItem: {
      styleOverrides: {
        root: {
          ':hover': {
            backgroundColor: themeColors.surface.darkBlue[10],
          },
        },
      },
    },
    MuiTreeView: {
      styleOverrides: {
        root: {
          border: `${themeVar.borderHeight}px solid ${themeColors.surface.darkBlue[25]}`,
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        root: {
          backgroundColor: themeColors.surface.darkBlue[10],
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          backgroundColor: themeColors.surface.darkBlue[30],
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        outlined: {
          backgroundColor: themeColors.surface.darkBlue[30],
        },
      },
    },
    MuiRadio: {
      styleOverrides: {
        root: {
          '& svg': {
            width: '.9em',
            height: '.9em',
          },
        },
      },
    },
  },
});

export default lightTheme;
