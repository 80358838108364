import Button from '@mui/material/Button';
import text from '../../../global/text.json';

const releasesText = text.releases;
import { releasesPageIds } from 'tests/helpers/dataTestIds';
import { CustomDialog } from '@mid-react-common/common';
import { DynamicContent } from '@adsk/offsite-dc-sdk';
import { ViewIn3dHeader } from './ViewIn3dHeader';
import { DCProductUIExtension } from 'mid-types';
import { useViewIn3d } from './useViewIn3d';
import { LmvWrapper } from './LmvWrapper';
import { useMemo, FC } from 'react';
import { ProductReleaseData } from 'lmv-ext-ind/src/utils/types'; // eslint-disable-line monorepo-imports/no-relative-imports

export type ViewIn3dProps = {
  productRelease: DCProductUIExtension<DynamicContent>;
};

export const ViewIn3d: FC<ViewIn3dProps> = ({ productRelease }) => {
  const {
    accountId,
    accountName,
    projectName,
    productReleaseName,
    productReleaseVersion,
    dialogOpen,
    handleViewIn3dClick,
    handleDialogClose,
  } = useViewIn3d({ productRelease });

  // TODO: add ACC bridge support
  const isProductDataAvailable = Boolean(
    accountId && productRelease.contentId && productRelease.tenancyId && productRelease.release,
  );

  const productData = useMemo(
    () =>
      ({
        accountId: accountId!,
        projectId: productRelease.tenancyId,
        productId: productRelease.contentId,
        releaseNumber: productRelease.release,
      }) satisfies ProductReleaseData,
    [accountId, productRelease.contentId, productRelease.tenancyId, productRelease.release],
  );

  return (
    <>
      {!accountName || !projectName ? (
        releasesText.noAccountProjectDataAvailable
      ) : (
        <>
          <Button onClick={handleViewIn3dClick} variant="contained" data-testid={releasesPageIds.buttonViewIn3d}>
            {releasesText.viewIn3d}
          </Button>
          <CustomDialog
            fullScreen
            title={
              <ViewIn3dHeader
                accountName={accountName}
                projectName={projectName}
                productReleaseName={productReleaseName}
                productReleaseVersion={productReleaseVersion}
              />
            }
            open={dialogOpen}
            dividers
            maxWidth="md"
            customActions={[
              <Button
                key={releasesPageIds.buttonViewIn3dClose}
                data-testid={releasesPageIds.buttonViewIn3dClose}
                variant="contained"
                onClick={handleDialogClose}
              >
                {releasesText.close}
              </Button>,
            ]}
          >
            {dialogOpen && isProductDataAvailable && <LmvWrapper productReleaseData={productData} />}
          </CustomDialog>
        </>
      )}
    </>
  );
};
